import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";
import Dibujo3d from "../../static/images/solutions/servicio_botella_pet/c1.png";

const Service_one = () => {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{
                            backgroundImage: `url(../images/solutions/servicio_botella_pet/1.jpg)`,
                        }}
                    >
                        <h1>Solución para plantas e ingeniería de botella a botella de PET</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Antecedentes</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Hay una fuerte señal en todo el mundo sobre una tendencia constante entre las marcas y
                                los minoristas de bienes de consumo globales, de aumentar el contenido reciclado en sus
                                empaques a un promedio del 25% para el año 2025. Estos esfuerzos de la iniciativa de"
                                cerrar el círculo "se han convertido en una competencia real entre las marcas
                                internacionales en estos últimos tiempos.
                                <br/>
                                <br/>
                                Mientras tanto, todas las principales empresas de preformas de botellas utilizan con
                                éxito el PET reciclado, combinado en la fabricación de preformas, a veces en una
                                proporción del 100%. Múltiples estudios de viabilidad muestran que esto no solo es ideal
                                para el medio ambiente, sino que también aporta importantes beneficios económicos, que
                                son vitales para cualquier negocio.
                                <br/>
                                <br/>
                                Zion Tech es el proveedor líder de soluciones de reciclaje de PET postconsumo en Asia.
                                Estamos orgullosos de nuestros 30 años de fabricación de fibra de poliéster y reciclaje
                                de desechos plásticos. En la actualidad, tenemos más de 170 líneas de reciclaje de
                                plásticos que funcionan con éxito en más de 30 países en todo el Globo. Nuestros
                                clientes en los EE. UU., Japón y la UE fabrican resina rPET de grado alimenticio para
                                propietarios de marcas tan conocidas como Niagara Bottling, Nestlé, etc. Zion Tech ofrece
                                un sistema completo de reciclaje de botella a botella, la conversión desde los fardos de
                                botellas de PET postconsumo, luego proecesar a través de la trituración y el lavado para
                                grado alimenticio, hasta convertirse en gránulos de rPET, dichos gránulos rPET son aptos
                                para el contacto directo con alimentos (con aprobación FDA / EFSA).
                                <br/>
                                <br/>
                                El proceso es completamente continuo e integra la extrusión como una primera sección del
                                proceso y la policondensación en estado sólido como una segunda sección del proceso en
                                un sistema integrado. Se puede construir un sistema de control automático e inteligente
                                completamente avanzado en la línea para una operación mejor y fácil. Este sistema se
                                puede diseñar para tener una optimización de control basada en cada línea de producción,
                                además, podemos desarrollar módulos personalizados, etc., con las funciones mencionadas
                                anteriormente, somos capaces de mejorar la operación del producto, el monitoreo de
                                dispositivos y la gestión de la producción .
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Característica de producto</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>- Características de la extrusora:<br/>
                                Este nuevo sistema de extrusión reúne las ventajas específicas de la peletización y
                                homogeneización de material con el doble husillo cónico paralelo, El husillo está
                                equipado con elementos de corte y mezcla, fácilmente intercambiables que permiten
                                ajustar la plastificación exactamente. La desgasificación efectiva respaldada por
                                una bomba de vacío, elimina los monómeros en la parte frontal de la unidad de
                                plastificación.<br/>
                                • Gran sección de ingesta / muy buen comportamiento en la alimentación.<br/>
                                • El diseño cónico garantiza un buen llenado de tornillos. <br/>
                                • Alto rendimiento por rotación de tornillo (kg / rpm).<br/>
                                • Un posible tuerque elevado debido el gran diámetro del eje del tornillo.<br/>
                                • Comportamiento de presión muy estable.<br/>
                                • Excelente homogeneización que ofrece el sistema de co-rotación.<br/>
                                • Excelente rendimiento de alimentación.<br/>
                                • Distribución pequeña del tiempo de permanencia de la masa fundida dentro de la
                                unidad de procesamiento.<br/>
                                • Por lo tanto, baja temperatura de fusión.<br/>
                                • Por lo tanto, bajo consumo de energía.<br/>
                                <br/>
                                - Características de SSP<br/>
                                En el proceso SSP, los gránulos de PET se descontaminan aún más y se aumenta su
                                peso molecular para obtener resina de alta calidad para botellas, que cumple con
                                las regulaciones de las autoridades y los requisitos de la industria del
                                envasado. SSP y la descontaminación de sustancias migradas son procesos de
                                difusión controlada. Los principales parámetros de diseño son la temperatura del
                                producto y el tiempo de residencia.<br/>
                                El proceso de policondensación de estado sólido (SSP) es necesario para
                                actualizar el peso molecular de los gránulos de PET amorfo a resina de alta
                                calidad para botellas, que cumple con las regulaciones de la FDA y los
                                requisitos de la industria del envasado.<br/>
                                <br/>
                                • Especialmente indicado para pequeñas y medianas empresas de países del
                                tercer mundo que no dispone de instalación de polimerización.<br/>
                                • Chips de alta Viscosidad Intrínseca (I.V.) para los usuarios.<br/>
                                • Desempeño estable, inversión económica y alto valor C / P.<br/>
                                • La experiencia a largo plazo y el método de control de calidad de la línea
                                de lavado proporcionarán una calidad excelente y estable de escamas de PET
                                para la alimentación continua y estabilizada de peletización y SSP.<br/>
                                • Ahorro de energía y amigable con el medio ambiente: reutilización de la
                                energía térmica de la granulación y SSP en el proceso de lavado.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Nuestra fortaleza</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>-El proceso de lavado de Zion Tech es la única tecnología
                                diseñada en Asia que alcanzó los estándares LNO (Carta de no objeción) de la FDA de
                                EE.UU.<br/>
                                -Experiencia de instalación en todo el mundo: EE. UU., Unión Europea, Asia,
                                África, América Latina, India, etc.<br/>
                                -Enfoque primordial en I + D sostenible e ingeniería de vanguardia orientada a
                                recuperar el 100% de los residuos de PET posconsumo.<br/>
                                -El menor tiempo de entrega, instalación y puesta en marcha en la industria.<br/>
                                -Confianza y confianza de los clientes existentes, 2/3 de ellos tienen máquinas
                                de Zion Tech y que se solicita para ampliar su capacidad de producción.<br/>
                                -Experiencia en la fabricación de resina rPET de grado alimenticio con
                                importantes beneficios económicos para nuestros clientes.
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Capacidad de entrada</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <p style={{color: '#000'}}>
                                Entrada para línea de lavado: 1500/2000/3000 kg / h
                                <br/>
                                Entrada para SSP: 900-2000 kg / h
                            </p>
                        </div>
                    </div>
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Opciones de modelo</h1>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <img src={Dibujo3d} style={{width: "100%"}}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
